import { Directive, ElementRef, Renderer2, AfterContentInit } from '@angular/core';

@Directive({
  selector: "[appStyleMe]",
})
export class StyleMeDirective implements AfterContentInit {
  constructor(private elRef: ElementRef, private renderer: Renderer2) {}
 
  ngAfterContentInit() {
    const elval = this.elRef.nativeElement.textContent;
    const elNumber = +elval % 5;
    // Howler.volume(0.5);
    // change class
    switch (elNumber) {
      case 1:
        this.renderer.addClass(this.elRef.nativeElement, "div1");
        break;
      case 2:
        this.renderer.addClass(this.elRef.nativeElement, "div2");
        break;
      case 3:
        this.renderer.addClass(this.elRef.nativeElement, "div3");
        break;
      case 4:
        this.renderer.addClass(this.elRef.nativeElement, "div4");
        break;
      case 0:
        this.renderer.addClass(this.elRef.nativeElement, "div5");
        break;

      default:
        this.renderer.addClass(this.elRef.nativeElement, "div1");
        break;
    }
  }
}
