 <div class="wrapper">
   <h1>Exersice for Jessica</h1>
   <app-gamestart (numberFired)='getNumber($event)' (numbersArray)='resetNumbers($event)'></app-gamestart>
     <!-- <audio controls>
       <source src="../../src/app/sad.mp3" type="audio/mpeg">
       Your browser does not support the audio element.
     </audio> -->
   <section class="container">
     <div class="item-wrap" *ngFor=" let number of numbers; let i = index" >
       <div class="items" appStyleMe>
         <p>{{number}}</p>
       </div>
     </div>
   </section>
 </div>
