import { Component } from "@angular/core";
// import { Howl } from 'howler';

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.sass"],
})
export class AppComponent {

  title = "Game";
  numbers: number[] = [];

  // get the number from Event emmiter in GameStarComponent
  getNumber(myNr: number) {
    this.numbers.push(myNr);
  }

  // Reset Numbers from Event emmiter in GameStarComponent
  resetNumbers(numbersReset: boolean) {
    if (numbersReset) {
      this.numbers = [];
    }
    // this.numbers = numbersReset;
  }
}
