import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: "app-gamestart",
  templateUrl: "./gamestart.component.html",
  styleUrls: ["./gamestart.component.sass"],
})
export class GamestartComponent implements OnInit {
  @Output() numberFired = new EventEmitter<number>();
  @Output() numbersArray = new EventEmitter<boolean>();
  interval;
  lastNumber = 0;
  constructor() {}
  ngOnInit(): void {}

  onStartGame() {
    this.interval = setInterval(() => {
      this.lastNumber++;
      this.numberFired.emit(this.lastNumber);
    }, 1000);
  }

  onPauseGame() {
    clearInterval(this.interval);
  }

  onReset() {
    clearInterval(this.interval);
    this.lastNumber = 0;
    this.numbersArray.emit(true);
  }
}
